.auto-refill {
  padding-right: 67.5px;
  padding-left: 67.5px;
  padding-top: 60px;
  padding-bottom: 53px;
  background-color: #fff;
  border-radius: 20px;
  min-height: 100%;
  @media screen and (max-width: 1400px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media #{$desktop} {
    // padding-right: 67px;
    // padding-left: 67px;
    margin-bottom: 38px;
  }
  @media #{$ipad} {
    margin-bottom: 0;
  }
  @media #{$phone} {
    padding: 0;
    padding-bottom: 24px;
    background-color: transparent;
  }
  @media screen and (max-width: 600px) {
    padding-bottom: 24px;
  }
  &-history {
    &__empty {
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: -0.2px;
      max-width: 390px;
    }
    .btn {
      margin-top: 26px;
      font-family: 'Graphik-Semibold';
      font-weight: 600;
      @include font-size(16);
      line-height: 2.12;
      width: 100%;
      max-width: 360px;
      padding: 8.5px;
      box-shadow: 0px 6px 14px rgba(223, 223, 223, 0.5);
      border-radius: 28px;
      text-align: center;
    }
  }
  &__title {
    margin-bottom: 30px;
    @media #{$phone} {
      margin-bottom: 16px;
      @include font-size(28);
    }
  }
  &__logo {
    display: none;
    @media #{$phone} {
      display: block;
      margin-bottom: 36px;
    }
    img {
      width: 28px;
      height: 26.96px;
    }
    .disabled {
      pointer-events: none;
      cursor: default;
    }
  }
  &-plan {
    background: #FFFFFF;
    padding: 3px;
    box-shadow: 0px 12.5216px 12px rgba(0, 0, 0, 0.02), 0px 6.6501px 5px rgba(0, 0, 0, 0.01), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
    border-radius: 15px;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    @media screen and (max-width: 600px) {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
    &-header {
      background: #FFFFFF;
      display: flex;
      justify-content: space-between;
      padding: 34px 40px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      @media screen and (max-width: 1435px) {
        // align-items: center;
      }
      @media screen and (max-width: 600px) {
        padding: 20px;
        padding-bottom: 24px;
        background: #FFFFFF;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-top: 1px solid rgba(151, 151, 151, 0.2);
        border-left: 1px solid rgba(151, 151, 151, 0.2);
        border-right: 1px solid rgba(151, 151, 151, 0.2);
        box-shadow: 0px 12.5216px 12px rgba(0, 0, 0, 0.02), 0px 6.6501px 5px rgba(0, 0, 0, 0.01), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
      }
      &-right {
        @media screen and (max-width: 1435px) {
          padding-top: 4px;
        }
      }
      &-nextshipment {
        display: flex;
        margin-bottom: 2px;
        &__divider {
          margin-left: 8px;
          margin-right: 9px;
        }
        @media screen and (max-width: 600px) {
          margin-bottom: 0;
        }
      }
      &__nextshipment {
        @include font-size(20);
        line-height: 1.4;
        font-family: "Graphik-Semibold";
        letter-spacing: -0.5px;
        color: #000000;
        font-weight: 600;
        margin-bottom: 0;
        @media screen and (max-width: 600px) {
        }
      }
      &__address {
        font-weight: 400;
        @include font-size(16);
        line-height: 1.4;
        color: #000000;
        opacity: 0.6;
        br {
          display: none;
        }
        @media screen and (max-width: 1435px) {
          br {
            display: block;
          }
        }
        @media screen and (max-width: 600px) {
          @include font-size(12);
          opacity: 0.5;
          br {
            display: block;
          }
          span {
            display: none
          }
        }
      }
      &__date {
        @include font-size(18);
        line-height: 1.4;
        color: #000000;
        font-weight: 400;
      }
      &__status {
        margin-left: auto;
        font-family: "Graphik-Semibold";
        width: 44px;
        min-width: 44px;
        border-radius: 28px;
        height: 22px;
        color: #fff;
        display: flex;
        justify-content: center;
        span {
          font-weight: 700;
          @include font-size(12);
          line-height: 22px;
        }
      }
      &__status-label {
        @include font-size(16);
        font-family: 'Graphik-Semibold', sans-serif;
        line-height: 1.75;
        letter-spacing: -0.5px;
        color: #000000;
        font-weight: 600;
        border-radius: 6px;
        padding: 0 10px;
      }
      .label-VALID {
        background: rgba(41, 229, 215, 0.1);
        color: #29E5D7;
      }
      .label-EXPIRING, .label-PAUSED {
        background: rgba(255, 193, 64, 0.1);
        color: #FFC140;
      }
      .label-EXPIRED, .label-FAILED {
        background: rgba(247, 107, 134, 0.1);
        color: #F76B86;
      }
      .label-EMPTY {
        background: rgba(247, 107, 134, 0.1);
        color: #F76B86;
      }
      .label-INACTIVE {
        background: rgba(223, 223, 223, 0.3);
        color: #b8b8b8;
      }
    }
    &-main {
      background: #FFFFFF;
      @media screen and (max-width: 600px) {
        background: #FFFFFF;
        padding-bottom: 10px;
        border-top: 1px solid rgba(151, 151, 151, 0.2);
        border-left: 1px solid rgba(151, 151, 151, 0.2);
        border-right: 1px solid rgba(151, 151, 151, 0.2);
        box-shadow: 0px 12.5216px 12px rgba(0, 0, 0, 0.02), 0px 6.6501px 5px rgba(0, 0, 0, 0.01), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
      }
      &-filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px solid #EAEAEA;
        @media screen and (max-width: 600px) {
          padding: 20px;
        }
        &:last-of-type {
          // border-bottom: 1px solid #fff;
        }
        &-label {
          margin-left: 40px;
          @media screen and (max-width: 600px) {
            margin-left: 0px;
            line-height: 40px;
          }
          &__count {
            font-weight: 400;
            @include font-size(20);
            line-height: 28px;
            letter-spacing: -0.533333px;
            color: #000000;
            margin-right: 6px;
            @media screen and (max-width: 600px) {
              @include font-size(18);
              line-height: 1.56;
            }
          }
          &__text {
            font-family: 'Graphik-Medium';
            font-weight: 500;
            @include font-size(20);
            line-height: 28px;
            letter-spacing: -0.5px;
            color: #000000;
            @media screen and (max-width: 600px) {
              @include font-size(18);
              line-height: 1.56;
            }
          }
        }
        &-image {
          height: 80px;
          width: 116px;
          background-repeat: no-repeat;
          overflow: hidden;
          background-size: 145%;
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
      }
      .BS, .MHF20BS, .MHF30BS {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/big-sneeze.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .BSM, .MMF10BS {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/big-sneeze.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .CC, .MHF20CC, .MHF30CC {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/critter-cuddler.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .CCM, .MMF10CC {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/critter-cuddler.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .BB, .MHF20BB, .MHF30BB {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/basic-breather.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .BBM, .MMF10BB {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/basic-breather.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .RP, .RPM, .MHF20RP, .MHF30RP, .MMF10RP {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/rookie-parent.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .HW, .HWM, .MHF20HW, .MHF30HW, .MMF10HW {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/home-wrecker.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .MB, .MBM, .MHF20MB, .MHF30MB, .MMF10MB {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/mama-to-be.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .OR, .MHF20OR, .MHF30OR {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/the-overreactor.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
      .ORM, .MMF10OR {
        .auto-refill-plan-main-filter-image { background-image: url("../uploads/the-overreactor.png"); }
        .auto-refill-plan-main-filter-label__text { color: #000; }
      }
    }
    &-footer {
      background: #FFFFFF;
      padding: 30px 40px;
      display: flex;
      gap: 40px;
      justify-content: space-between;
      align-items: center;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      @media screen and (max-width: 600px) {
        padding: 20px;
        flex-direction: column;
        gap: 20px;
      }
      &-right {
        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }
      &-left {
        @media screen and (max-width: 600px) {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
      }
      &-manage {
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 0px 3px 5px #E0E0E0;
        border-radius: 28px;
        overflow: hidden;
        width: 100%;
        &_inactive {
          padding: 9px 36px;
          background: #FFFFFF;
          cursor: pointer;
          box-shadow: 0px 3px 5px #E0E0E0;
          border-radius: 28px;
          @media screen and (max-width: 600px) {
            padding: 9px 24px;
            width: 100%;
          }
          &:disabled {
            cursor: not-allowed;
          }
          &__text {
            font-family: 'Graphik-Semibold';
            font-weight: 600;
            @include font-size(16);
            line-height: 2.13;
            color: #000000;
            user-select: none;
            @media screen and (max-width: 600px) {
              @include font-size(14);
              line-height: 15px;
              letter-spacing: -0.5px;
            }
          }
        }
        &-header {
          padding: 9px 36px;
          background: #FFFFFF;
          cursor: pointer;
          display: flex;
          align-items: center;
          @media screen and (max-width: 600px) {
            padding: 12px 24px;
          }
          &__text {
            font-family: 'Graphik-Semibold';
            font-weight: 600;
            @include font-size(16);
            line-height: 2.13;
            color: #000000;
            user-select: none;
          }
          &__arrow {
            margin-left: 10px;
            user-select: none;
            @media screen and (max-width: 600px) {
              margin-left: auto;
            }
          }
          .open img {
            transform: rotate(180deg);
          }
        }

        &-wrap {
          position: relative;
          height: 52px;
          width: 244px;
          @media screen and (max-width: 600px) {
            height: 58px;
            width: 100%;
          }
        }
        &-options {
          position: relative;
          z-index: 1;
          padding: 3px 24px 29px 24px;
          background: #FFFFFF;
          @media screen and (max-width: 600px) {
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 10px;
          }
        }
        &-option {
          display: flex;
          align-items: center;
          background: #FFFFFF;
          border: 1px solid rgba(151, 151, 151, 0.2);
          border-radius: 200px;
          padding: 13px 12px;
          padding-right: 26px;
          margin-bottom: 10px;
          cursor: pointer;
          &:last-of-type {
            margin-bottom: 0;
          }
          &__icon {
            min-width: 30px;
          }
          &__label {
            font-family: 'Graphik-Medium';
            font-weight: 500;
            white-space: nowrap;
            @include font-size(16);
            line-height: 18px;
            letter-spacing: -0.2px;
            color: #000000;
            @media screen and (max-width: 600px) {
              @include font-size(14);
              padding-top: 4px;
              line-height: 15px;
              letter-spacing: -0.5px;
            }
          }
        }
      }
      &__amount-label {
        font-family: 'Graphik-Medium';
        font-weight: 500;
        @include font-size(18);
        line-height: 1.2;
        margin-bottom: 2px;
        @media screen and (max-width: 600px) {
          @include font-size(16);
          line-height: 1.2;
          margin-bottom: 0;
        }
      }
      &__amount {
        font-weight: 400;
        @include font-size(18);
        line-height: 1.2;
      }
    }
  }
  .VALID {
    background: #29E5D7;
  }
  .EXPIRING, .PAUSED {
    background: #FFC140;
  }
  .EXPIRED, .FAILED {
    background: #F76B86;
  }
  .INACTIVE {
    background: #dfdfdf;
  }
  .EMPTY {
    background: #F76B86;
  }
  &-inactive_plan {
    margin-top: 20px;
    border-radius: 15px;
    transition: height 0.3s;
    .auto-refill-plan {
      border: none;
      box-shadow: none;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      padding: 10px 26px;
      border: 1px solid #EAEAEA;
      align-items: center;
      background: #FFFFFF;
      border-radius: 15px;
      cursor: pointer;
      @media screen and (max-width: 600px) {
        padding: 27.5px 26px;
      }
      &__label {
        font-family: 'Graphik-Semibold';
        font-weight: 600;
        @include font-size(20);
        line-height: 50px;
        letter-spacing: -0.466667px;
        color: #000000;
        user-select: none;
        @media screen and (max-width: 600px) {
          @include font-size(14);
          line-height: 15px;
          letter-spacing: -0.285714px;
        }
      }
      &__arrow {
        user-select: none;
      }
      .open img {
        transform: rotate(180deg);
      }
    }
  }
}

.react-datepicker{
  border: 1px solid #EAEAEA;
  border-radius: 12px;
  padding: 8px 24px;
  width: 100%;
  font-family: "Graphik-Regular", sans-serif;
  &__month-container {
    width: 100%;
  }
  &__day, 
  &__time-name {
    width: 32px;
    line-height: 32px;
    font-weight: 400;
    @include font-size(20);
    letter-spacing: 0.38px;
    color: #979797;
    margin: 0;
    margin-right: 14px;
    @media #{$phone} {
      margin-right: 12.83px;
      color: #000000;
      @include font-size(18);
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__day {
    &--disabled {
      opacity: 0.6;
    }
    &:hover {
      border-radius: 50%;
    }
  }
  
  &__day-name {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    color: rgba(60, 60, 67, 0.3);
    letter-spacing: -0.078px;
    line-height: 18px;
    margin: 0;
    margin-right: 19px;
    @media #{$phone} {
      margin-right: 12.83px;
      width: 32px;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
  &__day-names {
    margin-bottom: 1px;
    margin-top: 1px;
  }
  &__week {
    padding: 10px 0;
    width: 100%;
    @media #{$phone} {
      padding: 6px 0;
    }
  }
  &__month {
    margin: 0;
  }
  &__day--outside-month {
    visibility: hidden;
  }
  &__day--selected,
  &__day--keyboard-selected,
  &__month-text--keyboard-selected,
  &__quarter-text--keyboard-selected,
  &__year-text--keyboard-selected {
    background-color: #1CE4D5;
    border-radius: 50%;
    color: #fff;
    &:hover {
      background-color: #1CE4D5;
      border-radius: 50%;
      color: #fff;
    }
  }
  &__header {
    background-color: #fff;
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 0;
    position: static;
  }
  &__navigation {
    width: 16px;
  }
  &__navigation--previous {
    left: 24px;
    top: 35px;
    @media #{$phone} {
      left: 53px;
      top: 20px;
    }
  }
  &__navigation--next {
    right: 24px;
    top: 35px;
    @media #{$phone} {
      right: 53px;
      top: 20px;
    }
  }
  &__navigation-icon--next {
    background: url('/src/assets/images/datepicker-right.svg') no-repeat;
    width: 10px;
    height: 17px;
    &::before {
      border: none;
    }
  }
  &__navigation-icon--previous {
    background: url('/src/assets/images/datepicker-left.svg') no-repeat;
    width: 10px;
    height: 17px;
    &::before {
      border: none;
    }
  }
  &__current-month{
    color: #979797;
    font-family: "Graphik-Regular", sans-serif;
    font-weight: 400;
    @include font-size(20);
    line-height: 1.2;
    margin: 7px auto;
    padding: 10px;
    @media #{$phone} {
      display: flex;
      justify-content: center;
      align-items: center;
      @include font-size(16);
      line-height: 1.5;
      letter-spacing: 0.38px;
      color: #000000;
    }
    &__year {
      margin-top: 3px;
      font-weight: 600;
      @media #{$phone} {
        margin-top: 0;
        margin-left: 3px;
        font-family: 'Graphik-Semibold';
        font-weight: 600;
        @include font-size(16);
        line-height: 1.5;
        letter-spacing: 0.38px;
        color: #000000;
      }
    }
  }
}


.message-popup {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  padding-left: 40px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #1CE4D5;
  border-radius: 58px;
  align-items: center;
  margin-bottom: 15px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  @media #{$phone} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 20px;
    padding-left: 24px;
    padding-right: 24px;
  }
  &-bg {
    position: fixed;
    width: calc(100% - 30px);
    top: 65px;
    left: 15px;
    z-index: 100;
    @keyframes fadeIn {
      from {
         opacity: 0;
      }
      to {
         opacity: 1;
      }
    }

    @keyframes fadeOut {
      from {
         opacity: 1;
      }
      to {
         opacity: 0;
      }
    }

    .fade-in {
      opacity: 0;
      animation: fadeIn ease-in 1;
      animation-fill-mode: forwards;
      animation-duration: 1s;
    }

    .fade-out {
      opacity: 1;
      animation: fadeOut ease-in 1;
      animation-fill-mode: forwards;
      animation-duration: 1s;
    }
  }
  &-text {
    font-family: 'Graphik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    color: #000;
    margin-bottom: 0;
    @media #{$phone} {
      font-size: 14px;
    }
  }
  &-close {
    cursor: pointer;
    margin-left: 20px;
    @media #{$phone} {
      display: none;
    }
    img {
      min-width: 36px;
    }
    &-mobile {
      cursor: pointer;
      display: none;
      margin-top: 20px;
      @media #{$phone} {
        display: flex;
        align-items: center;
        gap: 6px;
      }
      &__label {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}


@font-face {
  font-family: 'Graphik-Bold';
  src: url('assets/fonts/Graphik-Bold.woff2') format('woff2'),
  url('assets/fonts/Graphik-Bold.woff') format('woff'),
  url('assets/fonts/Graphik-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik-Semibold';
  src: url('assets/fonts/Graphik-Semibold.woff2') format('woff2'),
  url('assets/fonts/Graphik-Semibold.woff') format('woff'),
  url('assets/fonts/Graphik-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik-Medium';
  src: url('assets/fonts/Graphik-Medium.woff2') format('woff2'),
  url('assets/fonts/Graphik-Medium.woff') format('woff'),
  url('assets/fonts/Graphik-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik-Regular';
  src: url('assets/fonts/Graphik-Regular.woff2') format('woff2'),
  url('assets/fonts/Graphik-Regular.woff') format('woff'),
  url('assets/fonts/Graphik-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

.auto-refill-plan-status {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 13.5px 20px;
  &.EXPIRING {
    gap: 4px;
  }
  @media #{$phone} {
    flex-wrap: wrap;
    justify-content: center;
  }
  &__label {
    font-family: 'Graphik-Medium';
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: -0.2px;
    margin: 0;
  }
  &__button {
    font-family: 'Graphik-Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.2px;
    text-decoration-line: underline;
    cursor: pointer;
    margin-left: auto;
    &:disabled {
      cursor: not-allowed;
    }
    @media #{$phone} {
      flex-basis: 100%;
      text-align: center;
      padding-top: 8px;
    }
  }
}

.user-menu {
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 20px;
  top: 25px;
  display: none;
  @media #{$phone} {
    display: flex;
  }
  &__icon {
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    background: #8852F61A;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  &__name {
    font-family: 'Graphik-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    display: flex;
    gap: 6px;
  }
  &-items {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0px 6px 14px rgba(223, 223, 223, 0.5);
    width: 120px;
    text-align: center;
    z-index: 1;
    border-radius: 20px;
    &-item {
      width: 100%;
    }
    .logout {
      color: $red;
    }
  }
  .rotated {
    transform: rotate(-180deg);
  }
}
.main{
  position: relative;
}
.form-group{
  padding: 27px 20px 26px 20px;
  background-color: $white;
  border-radius: 15px;
  margin-bottom: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  border: 1px solid rgba($black, 0.1);
  @media #{$phone} {
    padding: 18px 20px 19px 20px;
    flex-direction: column-reverse;
    width: 100%;
    box-sizing: border-box;
  }
  label{
    font-family: $primary-family-medium;
    @include font-size(18);
    width: 40%;
    margin-right: 3%;
    @media #{$phone} {
      width: 100%;
      margin-right: 0;
      @include font-size(16);
    }
    &:before{
      content:"";
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  input,
  textarea{
    position: relative;
    z-index: 2;
    padding: 0;
    width: 57%;
    outline: none !important;
    @media #{$phone} {
      width: 100%;
      @include font-size(16);
    }
    &:focus + label:before{
      box-shadow: 0 14px 40px rgba(#D6D6D6, 0.4);
      border-radius: 15px;
      transition: all 0.3s ease;
    }
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #C7C7C7;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #C7C7C7;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #C7C7C7;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #C7C7C7;
    }
  }
  textarea{
    min-height: 110px;
  }
  .welcome-block__form &{
    margin-bottom: 24px;
    @media #{$phone} {
      margin-bottom: 10px;
      padding: 13px 20px;
    }
  }
}
.form-group--error{
  box-shadow: 0 0 0 3px #F42D53 inset;
}

/* Filter */
.filter-list{
  width: 52.5%;
  @media #{$ipad} {
    width: 100%;
    margin-bottom: 50px;
  }
  @media #{$phone} {
    margin-bottom: 0;
  }
}
.filter-list__item-option{
  padding-left: 0;
  list-style: none;
  margin-bottom: 30px;
  display: flex;
  width: 282px;
  @media #{$phone} {
    flex-wrap: wrap;
    margin-bottom: 5px;
  }
  li{
    display: block;
    width: 44%;
    line-height: 1;
    @media #{$phone} {
      width: 100%;
      margin-bottom: 20px;
    }
    &:first-child{
      border-right: 2px solid #CBCBCB;
      margin-right: 18px;
      width: 56%;
      @media #{$phone} {
        width: 100%;
        border: none;
      }
    }
    strong{
      font-family: $primary-family-medium;
      @include font-size(16);
      display: block;
      margin-bottom: 3px;
    }
    span{
      color: $black;
    }
  }
}
.filter-list__item{
  padding: 34px;
  border-radius: 15px;
  box-shadow: 0 41px 33px rgba(0, 0, 0, 0.0288817);
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  margin-bottom: 20px;
  position: relative;
  @media #{$phone} {
    padding: 18px 32px 17px;
  }
}
.filter-list__item-image{
  max-width: 212px;
  position: absolute;
  right: -80px;
  margin-top: -20px;
  margin-bottom: -20px;
  @media #{$phone} {
    right: -90px;
    top: -30px;
  }
}
.filter-list__item-number{
  font-family: $primary-family-medium;
  @include font-size(18);
}
.filter-list__item-link{
  color: $black;
  &:hover,
  &:focus{
    text-decoration: none;
  }
  @media #{$phone} {
    @include font-size(18);
  }
}
.filter-list__item-content{
  min-height: 155px;
}
.filter-list__item-trigger{
  margin-bottom: 26px;
  display: flex;
  @media #{$phone} {
    margin-bottom: 16px;
  }
  p{
    font-family: $primary-family-medium;
    @include font-size(16);
    margin-right: 5px;
    margin-bottom: 0;
  }
}
.filter-list__title{
  @include font-size(22);
  margin-bottom: 8px;
  display: block;
  margin-top: 4px;
  @media #{$phone} {
    max-width: 150px;
    @include font-size(20);
    font-family: $primary-family-semibold;
    line-height: 1.3;
    margin-bottom: 15px;
  }
  img{
    max-width: 22px;
    display: inline-block;
    margin-top: -4px;
  }
}
.refill-button{
  @include font-size(12);
  text-transform: uppercase;
  background-color: #D1D1D1;
  padding: 2px 5px;
  border-radius: 28px;
  width: 40px;
  height: 20px;
  text-align: center;
  color: $white;
  font-family: $primary-family-semibold;
  &--is-active{
    background-color: $primary-color;
  }
}

.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  position: relative;
  vertical-align: middle;
  width: 1em;
}

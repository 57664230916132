.top-bar {
  text-align: center;
  background-color: #262626;
  color: $white;
  padding: 14px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  @media #{$phone} {
    padding: 8px 0;
  }

  &__container {
    margin: 0 auto;
    max-width: 1365px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
  }

  &__info {
    font-family: $primary-family;
    @include font-size(16);

    @media #{$phone} {
      @include font-size(14);
    }

    b {
      font-family: $primary-family-bold;
    }

    span {
      font-family: $primary-family-semibold;
    }

    strong {
      font-family: $primary-family-bold;
    }

    img {
      max-width: 18px;
      display: inline-block;
      margin-top: -4px;
    }
  }

  +.header-refactored {
    top: 50px;

    @media #{$phone} {
      top: 56px;
    }
  }

  &.top-bar--red {
    background-color: #F42D53;
    color: $white;
  }

  ~.main {
    padding-top: 161px;

    @media #{$phone} {
      padding-top: 130px;
    }
  }
}

.header{
  padding: 30px 34px 24px 34px;
  position: relative;
  z-index: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  @media #{$phone} {
    padding: 8px 16px 8px 24px;
  }
}

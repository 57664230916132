.modal-edit-card {
  &-wrap {
    display: flex;
    gap: 16px;
    margin-top: 16px;
  }
  &-input {
    padding: 16px 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    box-sizing: border-box;
    &__label {
      font-family: 'Graphik-Regular';
      font-weight: 400;
      font-size: 16px;
      line-height: 1.125;
      letter-spacing: -0.385882px;
      color: #404847;
      margin-bottom: 6px;
    }
    &__input {
      max-width: 100%;
      padding: 0 !important;
    }
  }
  &-name {
    width: 100%;
  }
  &-number {
    width: 100%;
  }
  &-date {
    width: 105px;
  }
  &-cvc {
    width: 86px;
  }
}
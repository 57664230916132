.exclusive-members {
  position: relative;
  overflow: hidden;
  background: #fff;
  width: 100%;
  padding: 60px;
  padding-left: 69px;
  padding-top: 44px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  background-size: auto auto;
  background-repeat: no-repeat;
  background-position: right;
  border-radius: 20px;
  box-shadow: 0px 12.5216px 12px rgba(0, 0, 0, 0.02), 0px 6.6501px 5px rgba(0, 0, 0, 0.01), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
  @media #{$ipad} {
    width: auto;
    padding: 20px;
    margin: 20px;
    background-size: 0px 0px;
  }
  @media #{$phone} {
    margin: 0px;
    margin-bottom: 20px;
  }
  &-title {
    margin-bottom: 40px;
    @media (min-width: $ipad-width) {
      font-size: 28px;
      line-height: 1.79;
    }
  }
  &-text {
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 70px;
    max-width: 350px;
    @media #{$phone} {
      font-size: 14px;
      max-width: 100%;
    }
  }
}

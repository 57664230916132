.suggestions {
  &-container {
    position: absolute;
    top: 100%;
    left: -24px;
    background-color: #fff;
    max-height: 114px;
    overflow: auto;
    z-index: 2;
    width: calc(100% + 24px);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    padding-left: 24px;
    padding-right: 24px;
    @media #{$phone} {
      left: 0;
      width: 100%;
    }
    .suggestion {
      padding: 5px 0;
    }
  }
}

.google-places-autocomplete__suggestions {
  position: absolute;
  top: 100%;
  left: -24px;
  z-index: 2;
  width: 100%;
  background-color: #fff;
  padding-left: 24px;
  @media #{$phone} {
    left: 0;
  }
}

.google-places-autocomplete__input {
  width: 100%;
}

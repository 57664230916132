.modal-sorry {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  &__text {
    font-family: 'Graphik-Regular';
    font-weight: 400;
    @include font-size(22);
    line-height: 1.55;
    letter-spacing: -0.5px;
    color: #000000;
    max-width: 522px;
    margin: 0 auto;
  }
  &-moment {
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.2);
    border-radius: 200px;
    padding: 13px 20px 13px 12px;
    display: flex;
    gap: 14px;
    align-items: center;
    cursor: pointer;
    &-icon {
      height: 24px;
      min-width: 24px;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 0px 1px rgba(151, 151, 151, 0.2) inset;
      border-radius: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }
    .active {
      background-color: #1CE4D5;
      box-shadow: 0px 0px 0px 1px #18D5C6 inset;
      border: none;
    }
    &__text {
      font-family: 'Graphik-Medium';
      font-weight: 500;
      @include font-size(16);
      line-height: 1.13;
      letter-spacing: -0.2px;
      color: #000000;
    }
  }
}
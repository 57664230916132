/*--------------------------------------------------------------
# Color
--------------------------------------------------------------*/
$primary-color: #1CE4D5;
$light-primary-color: #6edbd4;
$gray: #8F8F8F;
$black: #000000;
$white: #ffffff;
$light-gray: #e7e7e7;
$red: #F42D53;

/*--------------------------------------------------------------
# Fonts
-------------------------------------------------------------*/
$primary-family: 'Graphik-Regular', sans-serif;
$primary-family-bold: 'Graphik-Bold', sans-serif;
$primary-family-semibold: 'Graphik-Semibold', sans-serif;
$primary-family-medium: 'Graphik-Medium', sans-serif;

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
$font__code: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
$font__pre: "Courier 10 Pitch", Courier, monospace;
$font__line-height-body: 1.4;
$font__line-height-pre: 1.6;

/*--------------------------------------------------------------
# Media Queries
--------------------------------------------------------------*/
$container: 1280px;
$ipad-width: 1024px;
$phone-width: 768px;
$phone-portrait-width: 450px;

$desktop: 'screen and (max-width: #{$container})';
$ipad: 'screen and (max-width: #{$ipad-width})';
$phone: 'screen and (max-width: #{$phone-width})';
$phone-portrait: 'screen and (max-width: #{$phone-portrait-width})';

.sidebar-menu-item {
  margin-bottom: 10px;
  &-link {
    display: flex;
    align-items: center;
    padding: 15px 30px;
    border-radius: 15px;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
    transition-duration: 200ms;
    white-space: nowrap;
    font-family: 'Graphik-Medium', sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.89;
    color: #8D8D8D;
    &:hover{
      color: #000;
      background-color: #fff;
    }
    .icon {
      height: unset;
      width: unset;
      max-width: 100%;
      max-height: 100%;
      &-container {
        width: 20px;
        min-width: 20px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: inline-flex;
        margin-right: 10px;
      }
     
    }
  }
  &.selected .sidebar-menu-item-link {
    color: #000;
    background-color: #fff;
  }
}
/*--------------------------------------------------------------
# Tailwind
--------------------------------------------------------------*/
@tailwind base;
@tailwind components;
@tailwind utilities;

/*--------------------------------------------------------------
# Helper
--------------------------------------------------------------*/
@import "helper/variables";
@import "helper/mixins";

/*--------------------------------------------------------------
# Font-face
--------------------------------------------------------------*/
@import "base/fonts";

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import "base/typography";

/*--------------------------------------------------------------
# Button
--------------------------------------------------------------*/
@import "layout/app";

/*--------------------------------------------------------------
# Button
--------------------------------------------------------------*/
@import "layout/button";

/*--------------------------------------------------------------
# Form
--------------------------------------------------------------*/
@import "layout/forms";

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
@import "layout/header";
@import "layout/header-demo";

/*--------------------------------------------------------------
# Vendors
--------------------------------------------------------------*/
@import 'swiper/swiper';
@import "swiper/components/scrollbar/scrollbar";
@import "react-datepicker/src/stylesheets/datepicker";

/*--------------------------------------------------------------
# Component-specific styles
--------------------------------------------------------------*/
@import "components/components";

/*--------------------------------------------------------------
# Page-specific styles
--------------------------------------------------------------*/
@import "pages/pages";

.modal-account {
  .modal-main {
    background: #F8F8F8;
    padding-bottom: 80px;
    @media #{$phone} {
      padding-bottom: 100px;
    }
  }

  .modal__title {
    font-family: 'Graphik-Semibold';
    font-weight: 600;
    font-size: 28px;
    line-height: 1.21;
    color: #000000;
    max-width: 100%;
    &__update_adress {
      font-family: 'Graphik-Semibold';
      font-weight: 600;
      font-size: 28px;
      line-height: 1.21;
      color: #000000;
      max-width: 100%;
      margin-left: 16px;
    }
  }
  .account-card__label {
    margin-bottom: 10px;
    line-height: 1;
  }
  .account-address__label {
    margin-bottom: 0;
  }
  &-wrap {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    @media #{$phone} {
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  &-card {
    padding-top: 10px;
    max-width: 480px;
    width: 100%;
    @media #{$phone} {
      margin-bottom: 38px;
      max-width: 100%;
    }
    &-wrap {
      gap: 16px;
      display: flex;
      flex-wrap: wrap;
    }
    &-field {
      padding: 16px 22px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 15px;
      box-sizing: border-box;
      transition: 0.3s;
      position: relative;
      &:hover {
        box-shadow: 0px 14px 40px rgba(214, 214, 214, 0.4);
      }
      @media #{$phone} {
        width: 100%;
      }
      &__label {
        font-family: 'Graphik-Semibold';
        @include font-size(18);
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.385882px;
        color: #404847;
        margin: 0;
        margin-bottom: 5px;
      }
      &__input {
        line-height: 1;
        &.reduced-font-size {
          input {
            @include font-size(16);
          }
        }
        input {
          font-family: 'Graphik-Regular';
          padding: 0;
          font-style: normal;
          font-weight: 600;
          @include font-size(18);
          line-height: 20px;
          letter-spacing: -0.385882px;
          max-width: 100%;
          width: 100%;
        }
      }
    }
    &-checkbox{
      background: none;
      display: flex;
      box-shadow: none !important;
      align-items: center;
      padding-left: 0;
      &:hover {
        box-shadow: none;
      }
      .modal-account-card-field__label {
        order: 1;
        font-family: 'Graphik-Medium';
        font-weight: 500;
        margin-left: 12px;
        margin-bottom: 0;
      }
      .modal-account-card-field__input {
        display: flex;
      }
    }
    &-number {
      width: 242px;
      @media (max-width: 1170px) {
        width: 100%;
      }
    }
    &-cvc {
      width: 86px;
      @media #{$phone} {
        width: 100%;
      }
    }
    &-expiration {
      width: 120px;
      @media #{$phone} {
        width: 100%;
      }
    }
  }
  &-address {
    max-width: 552px;
    margin-left: 16px;
    @media #{$phone} {
      max-width: 100%;
      margin-left: 0;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      margin-bottom: 10px;
      @media #{$phone} {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-shipping {
      display: flex;
      gap: 12px;
      align-items: center;
      @media #{$phone} {
        margin-left: auto;
      }
      &__label {
        font-size: 16px;
        line-height: 1;
      }
    }
    &-wrap {
      gap: 16px;
      display: flex;
      flex-wrap: wrap;
    }
    .modal-edit-address{
      &-state {
        width: calc(58.51% - 8px);
        @media #{$phone} {
          width: 100%;
        }
      }
      &-zipcode {
        width: calc(41.48% - 8px);
        @media #{$phone} {
          width: 100%;
        }
      }
    }
    .modal-account-card-field__input input {
      &::-webkit-input-placeholder {
        font-family: 'Graphik-Regular';
        font-weight: 400;
      }
      &::-moz-placeholder {
        font-family: 'Graphik-Regular';
        font-weight: 400;
      }
      &::-ms-input-placeholder {
        font-family: 'Graphik-Regular';
        font-weight: 400;
      }
    }
  }
}

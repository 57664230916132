.modal-background {
  position: fixed;
  width: 100vw;
  height: 100%;
  background: rgba(66, 66, 66, 0.6);
  backdrop-filter: blur(5px);
  left: 0;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  padding-top: 50px;
  @media (max-height: 910px), (max-width: 1450px) {
    padding-top: 110px;
  }
  @media #{$phone} {
    align-items: flex-end;
  }
}
.modal-edit-base {
  .modal-main {
    @media #{$phone} {
      padding-top: 0px;
      padding-left: 10px;
      padding-right: 10px;
      max-height: calc(100% - 100px);
    }
  }
  .modal-swipe-close {
    background: #F8F8F8;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 0;
  }
  .modal-layer-second {
    .modal-swipe-close {
      background: none;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 2px;
    }
  }
}
.modal-account-edit-address-base {
  .modal-main {
    background: #F8F8F8;
    border-radius: 15px;
    padding: 40px 60px;
  }
}
.modal-layer-second {
  z-index: 3;
}
.modal-main {
  background-color: #fff;
  max-width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  overflow: hidden;
  overflow-y: auto;
  padding: 40px;
  box-shadow: 0px 12.5216px 10px rgba(0, 0, 0, 0.0118623), 0px 6.6501px 5px rgba(0, 0, 0, 0.00602135), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
  border-radius: 15px;
  cursor: auto;
  transition: height 0.3s;
  position: relative;
  @media #{$phone} {
    padding-top: 0;
    max-width: 100%;
    max-height: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0px 16px 24px;
  }
}
.modal-delay,
.modal-delay-type {
  .modal-footer {
    margin-top: 80px;
    @media #{$phone} {
      margin-top: 26px;
    }
  }
}
.modal-delay {
  .modal-footer-row {
    @media #{$phone} {
      flex-direction: row;
      max-width: 279px;
      .btn-back {
        width: auto;
        order: -2;
      }
    }
  }
}
.resizing {
  transition-duration: 0s;
}
.modal-close {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  @media (max-width: 1100px) {
    display: none;
  }
}
.modal-swipe-close {
  height: 30px;
  justify-content: center;
  align-items: center;
  display: none;
  @media #{$phone} {
    margin-bottom: 2px;
    display: flex;
  }
  &-line {
    height: 4px;
    width: 90px;
    background-color: #9D9D9D;
    border-radius: 2px;
  }
}
.modal-account {
  .modal-close {
    @media (max-width: 1100px) {
      display: block;
    }
  }
}
.modal__title {
  @include font-size(30);
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 16px;
  letter-spacing: -0.466667px;
  &-bigger {
    @include font-size(38);
    line-height: 1.32;
    margin-top: 0;
  }
  br {
    display: none;
  }
  @media #{$phone} {
    max-width: 274px;
    @include font-size(28);
    line-height: 1.2;
    margin: 26px auto 10px;
    br {
      display: block;
    }
  }
}
.modal-slider__title {
  font-family: "Graphik-Semibold";
  @include font-size(24);
  line-height: 2;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 48px;
  letter-spacing: -0.466667px;
  @media #{$phone} {
    @include font-size(16);
    line-height: 1.13;
    margin-top: 26px;
    margin-bottom: 16px;
    letter-spacing: -0.3px;
  }
}
.modal__description {
  font-weight: 400;
  max-width: 436px;
  margin: 0 auto;
  @include font-size(22);
  line-height: 1.5;
  color: #000;
  opacity: 0.5;
  &-bolder {
    font-weight: 600;
  }
  @media (max-width: 600px) {
    text-align: left;
    max-width: 320px;
    margin: 0;
    @include font-size(16);
  }
}
.modal__text {
  font-weight: 400;
  @include font-size(22);
  margin: 0 auto;
  max-width: 440px;
  line-height: 1.55;
  text-align: center;
  letter-spacing: -0.5px;
  color: #000000;
  @media #{$phone} {
    max-width: 251px;
    @include font-size(16);
    line-height: 1.31;
    letter-spacing: -0.3px;
  }
  .bolder {
    font-family: 'Graphik-Semibold';
    font-weight: 600;
  }
  .mailto-link {
    text-decoration-line: underline;
    color: #707070;
    cursor: pointer;
  }
}
.modal-footer {
  display: flex;
  justify-content: center;
  gap: 17px;
  margin-top: 40px;
  .btn {
    width: 252px;
    height: 60px;
  }
  .btn--primary {
    order: 1;
  }
  @media #{$phone} {
    flex-direction: column;
    margin: 26px auto 56px;
    max-width: 234px;
    gap: 10px;
    .btn {
      @include font-size(16);
      width: 100%;
      height: 52px;
    }
    .btn--primary {
      order: -1;
    }
  }
}
.modal-cancel-sure-second {
  .modal__title {
    @media #{$phone} {
      max-width: 240px;
      font-size: 24px;
      line-height: 1.2;
    }
  }
  .modal__text {
    max-width: 500px;
  }
}
.modal-cancel-sorry {
  .modal__text {
    max-width: 522px;
    @media #{$phone} {
      max-width: 250px;
    }
  }
  @media #{$phone} {
    .modal__title {
      max-width: 100%;
    }
  }
}

.modal-cancel-sure {
  .modal__text {
    max-width: 570px;
  }
}

.modal-edit-save {
  .modal__text {
    max-width: 475px;
  }
}

.modal-delete-variant {
  .modal__title {
    br {
      display: block;
      @media #{$phone} {
        display: none;
      }
    }
  }
}

.modal-close-bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.account {
  padding: 63px 30px 63px 78px;
  background: #FFFFFF;
  border-radius: 20px;
  min-height: calc(100vh - 212px);
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  @media #{$desktop} {
    width: 100%;
  }
  @media #{$phone} {
    min-height: 100%;
    padding: 20px;
    padding-top: 53px;
  }
  .swiper-slide {
    width: auto;
    height: auto;
  }
  .swiper-container {
    overflow: visible;
  }
  &-buttons {
    margin-top: 76px;
    @media #{$phone} {
      margin-top: 18px;
    }
    .btn {
      width: 227px;
      height: 58px;
    }
  }
  &-wrap {
    margin-top: 73px;
    display: flex;
    justify-content: space-between;
    @media #{$phone} {
      margin-top: 16px;
    }
  }
  &-title {
    font-family: 'Graphik-Medium';
    font-weight: 500;
    @include font-size(24);
    line-height: 1.42;
    color: #000000;
    margin-bottom: 35px;
  }
  &-header {
    margin-top: 40px;
  }
  &-subtitle {
    font-family: 'Graphik-Medium';
    font-weight: 500;
    @include font-size(20);
    line-height: 1.42;
    color: #000000;
    margin-bottom: 10px;
  }
  &-cards {
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    gap: 30px;
    @media #{$phone} {
      display: block;
      margin-left: -20px;
      margin-right: -20px;
      margin-top: 18px;
    }
  }
  &-card {
    background: #FFFFFF;
    box-shadow: 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0288817), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0194954), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.0118623), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00602135), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00203485);
    border-radius: 15px;
    padding: 30px 30px 50px 30px;
    display: flex;
    justify-content: space-between;
    max-width: 461px;
    flex-basis: 50%;
    @media (max-width: 980px) {
      flex-basis: 100%;
    }
    @media #{$phone} {
      flex-direction: column;
      min-width: 274px;
      width: fit-content;
      box-sizing: border-box;
      padding-top: 38px;
      padding-bottom: 48.5px;
      height: 100%;
    }
    &__primary {
      background: #FFF2D7;
      border-radius: 44px;
      padding: 0 8px;
      font-family: 'Graphik-Medium';
      font-weight: 500;
      font-size: 16px;
      line-height: 155.6%;
      color: rgba(0, 0, 0, 0.6);
      display: inline-block;
      margin-bottom: 20px;
    }
    &__label {
      font-family: 'Graphik-Regular';
      font-weight: 400;
      @include font-size(18);
      line-height: 1.56;
      color: rgba(0, 0, 0, 0.6);
      margin-bottom: 12px;
    }
    &-wrap {
      display: flex;
      align-items: center;
    }
    &__icon {
      margin-right: 8.55px;
    }
    &__number {
      font-family: 'Graphik-Semibold';
      font-weight: 600;
      @include font-size(18);
      line-height: 1.56;
      color: #000000;
    }
    &-buttons {
      @media #{$phone} {
        margin-top: 20px;
      }
      .btn {
        border: 2px solid #D0D0D0;
        border-radius: 40px;
        font-family: 'Graphik-Medium';
        font-weight: 500;
        @include font-size(16);
        line-height: 1.125;
        text-align: center;
        letter-spacing: -0.285714px;
        color: #000000;
        padding: 20px 40px;
        @media #{$phone} {
          padding: 14.5px 24px;
          width: 100%;
        }
        &:hover,
        &:active,
        &:focus {
          border-color: $primary-color;
          color: #fff;
        }
      }
    }
  }
  &-address {
    font-family: 'Graphik-Semibold';
    font-weight: 600;
    @include font-size(18);
    line-height: 1.56;
    color: #000000;
    &-wrap {
      margin-top: 33px;
      .account-card__label {
        margin-bottom: 8px;
      }
    }
  }
  &-noactive {
    &__link {
      font-family: 'Graphik-Medium';
      font-weight: 500;
      color: $primary-color;
    }
  }
}

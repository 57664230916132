.modal-delay {
  &-inner {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    @media #{$phone} {
      margin-top: 0;
    }
    .modal-slider__title {
      display: none;
      @media #{$phone} {
        display: block;
      }
    }
    .modal-delay-slider .swiper-slide {
      width: auto;
      height: auto;
      cursor: pointer;
    }
    &-left {
      width: 52.88%;
      padding-right: 55px;
      box-sizing: border-box;
      @media #{$phone} {
        width: 100%;
        padding-right: 0;
      }
    }
    &-right {
      width: 47.11%;
      box-sizing: border-box;
      &>div {
        max-width: 360px;
        margin: 0 auto;
        @media #{$phone} {
          max-width: 346px;
        }
      }
    }
    &__title {
      display: none;
      font-family: 'Graphik-Medium';
      font-weight: 500;
      @include font-size(20);
      line-height: 22px;
      letter-spacing: -0.466667px;
      color: #000000;
      margin-bottom: 16px;
      @media #{$phone} {
        display: block;
      }
    }
  }
  &-slider {
    .swiper-container {
      overflow: visible;
    }
    .swiper-slide {
      width: 213px;
      height: auto;
      cursor: pointer;
    }
  }
  &-type {
    &-inner {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      flex-wrap: wrap;
      gap: 16px;
      @media #{$phone} {
        flex-direction: column;
        gap: 0;
      }
    }
    &-option {
      flex-grow: 1;
      max-width: calc(50% - 8px);
      width: calc(50% - 8px);
      max-height: 79px;
      @media #{$phone} {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  &-footer {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-top: 70px;
    &-delete {
      margin-top: 54px;
    }
    .btn {
      width: 254px;
      height: 60px;
      @media #{$phone} {
        font-size: 14px;
        height: 44px;
        padding: 0;
      }
    }
    .btn--danger-white {
      width: auto;
      padding: 14px 20px;
    }
  }
  .modal__semititle {
    margin-top: 48px;
    @media #{$phone} {
      margin-top: 32px;
    }
  }
}

.modal-delay-option {
  box-sizing: border-box;
  padding: 13.5px 20px;
  background: #FFFFFF;
  border: 1px solid rgba(151, 151, 151, 0.2);
  box-shadow: 0px 12.5216px 10px rgba(0, 0, 0, 0.0118623), 0px 6.6501px 5px rgba(0, 0, 0, 0.00602135), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
  border-radius: 15px;
  margin-bottom: 16px;
  outline:1px solid transparent;
  cursor: pointer;
  transition: 0.25s;
  height: 79px;
  @media #{$phone} {
    padding: 20px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
  &:hover {
    background: rgba(28, 228, 213, 0.1);
    border: 1px solid #1CE4D5;
    box-shadow: 0px 0px 0px 1px #1CE4D5 inset;
    border-radius: 12px;
  }
  
  &-inner {
    display: flex;
    align-items: center;
    height: 100%;
    &-left {  
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      width: 46px;
      min-width: 46px;
      @media #{$phone} {
        display: none;
      }
    }
    &__label {
      margin: 0;
      font-family: 'Graphik-Medium';
      font-weight: 500;
      @include font-size(16);
      line-height: 1.75;
      color: #000;
      letter-spacing: -0.533333px;
      @media #{$phone} {
        font-family: 'Graphik-Semibold';
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.5px;
        margin-bottom: 4px;
      }
    }
    &__date {
      font-family: 'Graphik-Regular';
      font-weight: 400;
      @include font-size(16);
      line-height: 1.38;
      letter-spacing: -0.533333px;
      color: #000000;
      opacity: 0.6;
      @media #{$phone} {
        font-family: 'Graphik-Medium';
        font-weight: 500;
        @include font-size(14);
        line-height: 1.2;
        letter-spacing: -0.5px;
        color: #000000;
        opacity: 1;
      }
    }
  }
}

.modal-delay-type-option {
  box-sizing: border-box;
  padding: 16.5px 20px;
  background: #FFFFFF;
  border: 1px solid rgba(151, 151, 151, 0.2);
  box-shadow: 0px 12.5216px 10px rgba(0, 0, 0, 0.0118623), 0px 6.6501px 5px rgba(0, 0, 0, 0.00602135), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
  border-radius: 15px;
  // margin-bottom: 16px;
  outline: 1px solid transparent;
  cursor: pointer;
  transition: 0.25s;
  @media #{$phone} {
    padding: 20px;
    width: 100%;
    height: 100%;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
  &:hover {
    background: rgba(28, 228, 213, 0.1);
    border: 1px solid #1CE4D5;
    box-shadow: 0px 0px 0px 1px #1CE4D5 inset;
    border-radius: 12px;
  }
  &-inner {
    display: flex;
    align-items: center;
    height: 44px;
    @media #{$phone} {
      height: auto;
      flex-direction: column;
      align-items: flex-start;
      gap: 14px;
    }
    &-left {  
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      width: 36px;
      min-width: 36px;
      height: 36px;
      @media #{$phone} {
        width: 24.3px;
        min-width: 24.3px;
        height: 24.3px;
      }
    }
    &__text {
      p {
        margin: 0;
        font-family: 'Graphik-Medium';
        font-weight: 500;
        @include font-size(16);
        line-height: 1.375;
        color: #000;
        opacity: 0.8;
        letter-spacing: -0.533333px;
        max-width: 228px;
        @media #{$phone} {
          font-family: "Graphik-Semibold";
          line-height: 1.2;
          letter-spacing: -0.5px;
          opacity: 1;
        }
      }
    }
  }
}

.active {
  background: rgba(28, 228, 213, 0.1);
  border: 1px solid #1CE4D5;
  box-shadow: 0px 0px 0px 1px #1CE4D5 inset;
  border-radius: 12px;
}

@use "sass:math";

// Rem output with px fallback
@mixin font-size($sizeValue) {
  font-size: math.div($sizeValue, 16) + rem;
}

// Font face mixin
@mixin fontFace($font-family, $font-name, $file-path) {
  @font-face {
    font: {
      family: $font-family;
      weight: normal;
      style: normal;
    }
    src: url('#{$file-path+$font-name}.eot');
    src: local($font-family), local($font-family),
    url('#{$file-path+$font-name}.eot?#iefix') format('embedded-opentype'),
    url('#{$file-path+$font-name}.woff') format('woff'),
    url('#{$file-path+$font-name}.ttf') format('truetype');
  }
}

@layer utilities {
  .shadow-1 {
    box-shadow: 0px 12.5216px 12px rgba(0, 0, 0, 0.02), 0px 6.6501px 5px rgba(0, 0, 0, 0.01), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
  }
  .shadow-2 {
    box-shadow: 0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0288817), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0194954), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.0118623), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.00602135), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.00203485);
  }
  .shadow-3 {
    box-shadow: 0px 12.5216px 10px rgba(0, 0, 0, 0.0118623), 0px 6.6501px 5px rgba(0, 0, 0, 0.00602135), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
  }
  .shadow-4 {
    box-shadow: 0px 3px 5px #E0E0E0;
  }
}

.standalone-filters {
  display: flex;
  overflow: hidden;
  background: #fff;
  border-radius: 20px;
  width: 100%;
  margin: 20px;
  margin-left: 0;
  margin-right: 0;
  box-shadow: 0px 12.5216px 12px rgba(0, 0, 0, 0.02), 0px 6.6501px 5px rgba(0, 0, 0, 0.01), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
  @media #{$ipad} {
    width: auto;
    margin: 20px;
  }
  @media #{$phone} {
    margin: 0px;
    margin-bottom: 20px;
  }
  &-left {
    flex: 1 1 auto;
    width: 58.333333%;
    padding-left: 70px;
    padding-top: 60px;
    padding-bottom: 70px;
    @media #{$ipad} {
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
  &-right {
    flex: 1 1 auto;
    width: 41.666667%;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &-title {
    margin-bottom: 40px;
    white-space: nowrap;
    @media (min-width: $ipad-width) {
      font-size: 28px;
      line-height: 1.79;
    }
    @media #{$phone} {
      white-space: unset;
    }
  }
  &-text {
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 40px;
    max-width: 320px;
    @media #{$phone} {
      font-size: 14px;
      max-width: 100%;
    }
  }
  &-img-desktop {
    height: 100%;
    width: 100%;
    display: none;
    @media (min-width: 450px) {
      font-size: 28px;
      line-height: 1.79;
      display: block;
    }
  }
  &-img-mobile {
    height: 66.666667%;
    width: 100%;
    @media (min-width: 450px) {
      display: none;
    }
  }
  .btn {
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    letter-spacing: -0.285714px;
    @media #{$phone} {
      font-size: 14px;
    }
  }
}

.header-demo {
  display: none;
  position: fixed;
  transform: translate3d(0,0,0);
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 2;
  @media #{$desktop} {
    display: flex;
  }
  @media #{$phone} {
    display: flex;
    padding: 8px;
  }
}

.header-nav-demo {
  &-items {
    display: flex;
    width: 100%;
    &>a {
      box-sizing: border-box;
      flex-basis: 100%;
    }
  }
  &-item {
    box-sizing: border-box;
    padding-top: 4px;
    text-align: center;
    &__label {
      font-family: 'Graphik-Medium';
      font-weight: 500;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: -0.078px;
      color: #8D8D8D;
    }
    &__icon {
      text-align: center;
      margin: 0 auto;
      svg {
        fill: #8D8D8D;
        height: 20px;
        width: 20px;
      }
    }
  }
}

.is-active {
  .header-nav-demo-item__icon svg {
    fill: #1CE4D5;
  }
  .header-nav-demo-item__label {
    color: #000000;
  }
}
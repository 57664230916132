.modal-edit {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media #{$phone} {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: -20px;
      margin-right: -20px;
    }
    &-left {
      display: flex;
      flex-direction: column;
      @media #{$phone} {
        padding: 20px 20px 0 20px;
      }
    }
    &__date {
      font-weight: 400;
      @include font-size(22);
      line-height: 1.27;
      letter-spacing: -0.5px;
      color: #000000;
      @media #{$phone} {
        @include font-size(18);
        line-height: 1.56;
        opacity: 0.7;
      }
    }
    &__title {
      font-family: 'Graphik-Bold', sans-serif;
      font-weight: 700;
      @include font-size(38);
      line-height: 1.32;
      letter-spacing: -0.466667px;
      color: #000000;
      @media #{$phone} {
        font-family: 'Graphik-Semibold';
        font-weight: 600;
        @include font-size(24);
        line-height: 1.17;
      }
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media #{$phone} {
        width: 100%;
        align-items: center;
        order: -1;
        flex-direction: row;
        justify-content: space-between;
        background: #F8F8F8;
        padding: 8px 14px;
        padding-bottom: 18px;
      }
      .auto-refill-plan-footer-manage-wrap {
        width: 223px;
        @media screen and (max-width: 600px) {
          width: 200px;
        }
      }
    }
    &-auto_refill {
      display: flex;
      gap: 5px;
      background: #FFFFFF;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 43px;
      padding: 12px 14px;
      cursor: pointer;
      @media #{$phone} {
        background: none;
        box-shadow: none;
        padding: 0;
      }
      &__label {
        font-family: 'Graphik-Regular';
        font-weight: 400;
        @include font-size(16);
        line-height: 1.13;
        letter-spacing: -0.3px;
        color: #000000;
        @media #{$phone} {
          font-family: 'Graphik-Medium';
          font-weight: 500;
        }
      }
      &__status {
        @include font-size(16);
        line-height: 1.13;
        font-family: "Graphik-Semibold";
        font-weight: 600;
        letter-spacing: -0.3px;
        color: #1CE4D5;
        @media #{$phone} {
          font-family: 'Graphik-Medium';
          font-weight: 500;
        }
      }
      &__cancel {
        span{
          font-weight: 400;
          @include font-size(14);
          line-height: 2.54;
          letter-spacing: -0.22px;
          text-decoration-line: underline;
          color: #707070;
          cursor: pointer;
        }
      }
    }
  }
  &-top {
    background-color: #fff;
    margin-left: -40px;
    margin-right: -40px;
    padding-left: 50px;
    padding-right: 50px;
    @media #{$phone} {
      overflow: hidden;
      margin-left: -10px;
      margin-right: -10px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &-filters {
    margin-top: 49px;
    @media #{$phone} {
      margin-top: 10px;
    }
  }
  &-filter {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(151, 151, 151, 0.2);
    margin-right: -50px;
    @media #{$phone} {
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 0px;
      margin-right: 0;
    }
    &:first-of-type {
      border-top: 1px solid rgba(151, 151, 151, 0.2);
      @media #{$phone} {
        border-top: none;
      }
    }
    &-count {
      display: flex;
      background: #F7F7F7;
      box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.08);
      border-radius: 73px;
      padding: 2px;
      @media #{$phone} {
        order: 1;
        margin-left: auto;
      }
      &-minus,
      &-plus {
        min-width: 37px;
        height: 37px;
        background: #FFFFFF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      &-value{
        min-width: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-family: 'Graphik-Medium';
          font-weight: 500;
          @include font-size(16);
          line-height: 1.13;
        }
      }
      &-mobile {
        display: none;
        font-family: 'Graphik-Regular';
        font-weight: 400;
        @include font-size(18);
        line-height: 1.56;
        letter-spacing: -0.533333px;
        color: #000000;
        min-width: 29px;
      }
      .disabled {
        background: $light-gray;
        cursor: auto;
      }
    }
    &-name {
      font-family: 'Graphik-Semibold';
      font-weight: 600;
      @include font-size(22);
      line-height: 1.27;
      letter-spacing: -0.5px;
      color: #F42D53;
      margin-left: 20px;
      @media #{$phone} {
        @include font-size(18);
        line-height: 1.56;
        letter-spacing: -0.5px;
        color: #F42D53;
        margin-left: 2px;
      }

    }
    &-price {
      font-weight: 400;
      @include font-size(18);
      line-height: 1.2;
      color: #000000;
      margin-left: auto;
      @media #{$phone} {
        display: none;
      }
    }
    &-image {
      height: 124px;
      width: 135px;
      background-repeat: no-repeat;
      overflow: hidden;
      background-size: 145%;
      background-position-y: 22px;
      margin-left: 60px;
      @media #{$phone} {
        display: none;
      }
    }
  }
  .BS {
    .modal-edit-filter-image { background-image: url("../uploads/big-sneeze.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .CC {
    .modal-edit-filter-image { background-image: url("../uploads/critter-cuddler.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .BB {
    .modal-edit-filter-image { background-image: url("../uploads/basic-breather.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .RP {
    .modal-edit-filter-image { background-image: url("../uploads/rookie-parent.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .HW {
    .modal-edit-filter-image { background-image: url("../uploads/home-wrecker.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .MB {
    .modal-edit-filter-image { background-image: url("../uploads/mama-to-be.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .OR {
    .modal-edit-filter-image { background-image: url("../uploads/the-overreactor.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .BBM {
    .modal-edit-filter-image { background-image: url("../uploads/basic-breather-mini.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .BSM {
    .modal-edit-filter-image { background-image: url("../uploads/big-sneeze-mini.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .CCM {
    .modal-edit-filter-image { background-image: url("../uploads/critter-cuddler-mini.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  .ORM {
    .modal-edit-filter-image { background-image: url("../uploads/the-overreactor-mini.png"); }
    .modal-edit-filter-name { color: #000; }
  }
  &-add_filter {
    &-button {
      padding: 19.5px 0px;
      @media #{$phone} {
        padding-left: 0px;
        padding-right: 0px;
        display: block;
      }
    }
    &-slider {
      padding: 0 50px 30px 50px;
      margin-left: -50px;
      margin-right: -50px;
      overflow: hidden;
      @media #{$phone} {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 40px;
        margin-left: -20px;
        margin-right: -20px;
      }
      .swiper-container {
        overflow: visible;
      }
      .swiper-slide {
        width: auto;
        cursor: pointer;
        padding: 30px 20px;
        box-sizing: border-box;
        background: #FFFFFF;
        box-shadow: 0 0 0 1px rgba(151, 151, 151, 0.2) inset;
        border-radius: 20px;
        outline: 1px solid transparent;
        @media #{$phone} {
          padding: 10px 20px;
        }
        &__title {
          font-family: 'Graphik-Semibold';
          font-weight: 600;
          @include font-size(20);
          line-height: 1.1;
          letter-spacing: -0.4px;
          margin-bottom: 6px;
          @media #{$phone} {
            margin-bottom: 0;
            @include font-size(16);
            line-height: 18px;
            letter-spacing: -0.466667px;
          }
        }
        &__text {
          font-weight: 400;
          @include font-size(16);
          line-height: 1.13;
          color: rgba(0, 0, 0, 0.7);
          max-width: 194px;
          margin-bottom: 0;
          @media #{$phone} {
            display: none;
          }
        }
      }
      .BS, .MHF20BS, .MHF30BS, .MMF10BS {
        .swiper-slide__title { color: #FFC140; }
        &:hover { box-shadow: 0 0 0 2px #FFC140 inset; }
      }
      .CC, .MHF20CC, .MHF30CC, .MMF10CC {
        .swiper-slide__title { color: #F42D53; }
        &:hover { box-shadow: 0 0 0 2px #F42D53 inset; }
      }
      .BB, .MHF20BB, .MHF30BB, .MMF10BB {
        .swiper-slide__title { color: #3E4649; }
        &:hover { box-shadow: 0 0 0 2px #3E4649 inset; }
      }
      .RP, .MHF20RP, .MHF30RP {
        .swiper-slide__title { color: #8852F6; }
        &:hover { box-shadow: 0 0 0 2px #8852F6 inset; }
      }
      .HW, .MHF20HW, .MHF30HW {
        .swiper-slide__title { color: #F27E0F; }
        &:hover { box-shadow: 0 0 0 2px #F27E0F inset; }
      }
      .MB, .MHF20MB, .MHF30MB {
        .swiper-slide__title { color: #1CE4D5; }
        &:hover { box-shadow: 0 0 0 2px #1CE4D5 inset; }
      }
      .OR, .MHF20OR, .MHF30OR, .MMF10OR {
        .swiper-slide__title { color: #0A56EA; }
        &:hover { box-shadow: 0 0 0 2px #0A56EA inset; }
      }
    }
    &__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      @media #{$phone} {
        justify-content: space-between;
      }
      .modal-edit-add_filter-left-side {
        display: flex;
        flex-direction: row;
        align-items: center;
        @media #{$phone} {
          gap: 5px;
        }
      }
      .modal-edit-add_filter-right-side {
        display: flex;
        gap: 10px;
        margin-left: auto;
        .btn {
          font-size: 1rem;
          width: 110px;
        }
        @media #{$phone} {
          margin-left: auto;
          .btn {
            font-size: 1rem;
            width: 95px;
          }
        }
      }
      &-icon {
        background: #F7F7F7;
        box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.08);
        border-radius: 50%;
        min-width: 41px;
        height: 41px;
        padding: 2px;
        box-sizing: border-box;
        @media #{$phone} {
          order: 1;
        }
      }
      &-label {
        font-family: 'Graphik-Semibold';
        font-weight: 600;
        @include font-size(22);
        line-height: 1.27;
        letter-spacing: -0.5px;
        color: #000000;
        margin-left: 20px;
        @media #{$phone} {
          @include font-size(18);
          line-height: 1.56;
          margin-left: 0;
        }
      }
      &__icon {
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  &-footer {
    background: #F8F8F8;
    display: flex;
    margin-right: -40px;
    margin-bottom: -40px;
    margin-left: -40px;
    // padding: 0 10px
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    @media (max-width: 1170px) {
      flex-wrap: wrap;
    }
    @media #{$phone} {
      margin-left: -10px;
      margin-right: -10px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
    &-address,
    &-date {
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      width: 33.3333%;
      padding-left: 40px;
      padding-top: 30px;
      padding-right: 40px;
      padding-bottom: 43px;
      border-right: 1px solid #D9D9D9;
      @media (max-width: 1170px) {
        width: 40%;
      }
      @media #{$phone} {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #D9D9D9;
        padding-left: 20px;
      }
      @media #{$phone} {
        padding: 24px 20px;
      }
    }
    &-date {
      @media (max-width: 1170px) {
        border-right: none;
        padding-right: 10px;
        width: 60%;
      }
      @media #{$phone} {
        width: 100%;
      }
    }
    &-amount {
      box-sizing: border-box;
      padding-left: 40px;
      padding-top: 30px;
      padding-right: 40px;
      padding-bottom: 43px;
      width: 33.3333%;
      @media (max-width: 1170px) {
        width: 40%;
        border-right: 1px solid #D9D9D9;
        border-top: 1px solid #D9D9D9;
      }
      @media #{$phone} {
        width: 100%;
        border: none;
        padding: 24px 20px;
        padding-bottom: 80px;
      }
      &-field {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &-buttons {
      margin-top: auto;
      display: flex;
      gap: 10px;
      @media #{$phone} {
        flex-wrap: wrap;
      }
    }
    &-button {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.75;
      text-decoration-line: underline;
      color: #707070;
      cursor: pointer;
    }
    &__title {
      font-family: 'Graphik-Semibold';
      font-weight: 600;
      @include font-size(18);
      line-height: 1.56;
      color: #000000;
      margin-bottom: 10px;
    }
    &__text {
      font-weight: 400;
      @include font-size(18);
      line-height: 1.2;
      color: #000000;
      margin-bottom: 10px;
      @media #{$phone} {
        @include font-size(16);
      }
    }
    .auto-refill-plan-status-card {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 104px;
  box-sizing: border-box;
  &-content {
    display: flex;
    flex-wrap: wrap;
    min-height: calc(100vh - 104px);
    padding: 80px 20px 20px 310px;
    @media #{$desktop} {
      padding: 80px 0 0;
    }
    @media #{$phone} {
      min-height: calc(100vh - 66px);
    }
  }
  @media #{$phone} {
    padding-bottom: 66px;
  }
}

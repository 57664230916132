.history {
  width: 100%;
  &-main {
    padding: 64px 49px 63px 78px;
    box-sizing: border-box;
    background: #fff;
    border-radius: 20px;
    overflow: hidden;
    @media #{$desktop} {
      width: 100%;
      padding-left: 49px;
    }
    @media #{$phone} {
      min-height: 100%;
      padding: 20px;
      border-radius: 0;
      padding-top: 36px;
      background: #f8f8f8;
    }
  }
  &-empty {
    @media #{$phone} {
      min-height: 100%;
      padding: 20px;
      padding-top: 36px;
      background: #f8f8f8;
    }
  }
  &-items {
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 48px;
    @media #{$phone} {
      margin-top: 35px;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0px 12.5216px 10px rgba(0, 0, 0, 0.0118623), 0px 6.6501px 5px rgba(0, 0, 0, 0.00602135), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
    border: 1px solid rgba(151, 151, 151, 0.2);
    padding: 20px;
    width: 100%;
    position: relative;
    background: #fff;
    &__hidden {
      @media #{$phone} {
        padding-bottom: 50px;
      }
    }
    &-header {
      display: flex;
      justify-content: space-between;
      &__date {
        font-family: 'Graphik-Medium';
        font-weight: 500;
        font-size: 20px;
        line-height: 1.4;
        letter-spacing: -0.533333px;
        margin-bottom: 10px;
      }
      &__amount {
        font-size: 16px;
        line-height: 28px;
        letter-spacing: -0.2px;
      }
      &__seedetails {
        margin-top: 22px;
        display: inline-block;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        text-decoration-line: underline;
        color: #000000;
        cursor: pointer;
        @media #{$phone} {
          position: absolute;
          bottom: 12px;
          left: 20px;
        }
      }
      &-status{
        img {
          height: 21px;
          width: 21px;
          min-width: 21px;
        }
      }
      &-right{
        text-align: right;
      }
      @media #{$phone-portrait} {
        flex-direction: column-reverse;
      }
    }
    &-main {
      margin-top: 20px;
      display: flex;
      gap: 24px;
      width: 100%;
      @media #{$phone} {
        flex-direction: column;
      }
      &-details {
        flex-grow: 1;
        padding: 20px;
        &__title {
          font-family: 'Graphik-Medium';
          font-weight: 500;
          font-size: 18px;
          line-height: 1.56;
          letter-spacing: -0.533333px;
        }
        &-item {
          display: flex;
          padding-bottom: 20px;
          border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
          margin-bottom: 20px;
          &__count {
            font-family: 'Graphik-Semibold';
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            min-width: 32px;
          }
          &__name {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.2px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow-x: hidden;
          }
          &__price {
            margin-left: auto;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.2px;
            padding-left: 15px;
          }
        }
      }
      &-calc {
        &-item {
          display: flex;
          margin-bottom: 20px;
          &__text {
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.2px;
          }
          &__price {
            margin-left: auto;
            font-size: 16px;
            line-height: 18px;
            letter-spacing: -0.2px;
          }
        }
        &-total {
          display: flex;
          border-top: 2px solid #000000;
          padding-top: 20px;
          &__text {
            font-family: 'Graphik-Medium';
            font-weight: 500;
            font-size: 18px;
            line-height: 1.56;
            letter-spacing: -0.533333px;
          }
          &__price {
            margin-left: auto;
            font-family: 'Graphik-Medium';
            font-weight: 500;
            font-size: 18px;
            line-height: 1.56;
            letter-spacing: -0.533333px;
          }
        }
      }
      &-info {
        padding: 20px;
        border: 1px solid rgba(151, 151, 151, 0.2);
        border-radius: 15px;
        min-width: 400px;
        display: flex;
        flex-direction: column;
        @media #{$ipad} {
          min-width: 300px;
        }
        &__title {
          font-family: 'Graphik-Medium';
          font-weight: 500;
          font-size: 18px;
          line-height: 1.56;
          letter-spacing: -0.533333px;
          margin-bottom: 14px;
        }
        &-card {
          display: flex;
          align-items: center;
          gap: 9px;
          margin-bottom: 20px;
          &__number {
            font-size: 16px;
            line-height: 155.6%;
          }
        }
        &-address {
          margin-bottom: 24px;
        }
        &-tracking {
          margin-bottom: 24px;
          &__code {
            text-decoration-line: underline;
          }
        }
        &-button {
          margin-top: auto;
          .btn {
            width: 100%;
            font-size: 14px;
          }
        }
      }
    }
    &-buttons {
      display: flex;
    }
    &-details {
      transition: 0.3s;
      font-family: 'Graphik-Regular';
      font-weight: 400;
      @include font-size(16);
      line-height: 1.2;
      color: #000000;
      overflow: hidden;
      &-filters {
        margin-bottom: 20px;
      }
      &-address {
        margin-bottom: 20px;
      }
      &-address,
      &-filters,
      &-account {
        p {
          margin: 0;
        }
      }
    }
    .open {
      display: block;
    }
    .closed {
      display: none;
    }
  }
}

.modal-edit-address {
  &__title {
    font-family: 'Graphik-Semibold';
    font-weight: 600;
    @include font-size(28);
    line-height: 1.21;
    color: #000000;
  }
  &-main {
    margin-top: 46px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    box-sizing: border-box;
    padding-right: 36px;
  }
  &-input {
    width: 100%;
    display: flex;
    height: 78px;
    background: #FFFFFF;
    border-radius: 15px;
    align-items: center;
    box-sizing: border-box;
    transition: 0.3s;
    &:hover {
      box-shadow: 0px 14px 40px rgba(214, 214, 214, 0.4);
    }
    &__label {
      font-family: 'Graphik-Medium';
      font-weight: 500;
      @include font-size(18);
      line-height: 20px;
      color: #000000;
      width: 230px;
      max-width: 230px;
      margin-bottom: 0;
      padding-left: 20px;
      flex-grow: 1;
      box-sizing: border-box;
    }
    &__input {
      flex-grow: 1;
      margin-right: 2px;
      position: relative;
      input {
        width: 100%;
        font-family: 'Graphik-Regular';
        font-style: normal;
        @include font-size(18);
        line-height: 20px;
        padding: 0;
        &::placeholder {
          color:#C7C7C7;
        }
      }
    }
  }
  &-state {
    width: calc(58.51% - 5px);
  }
  &-zipcode {
    width: calc(41.48% - 5px);
  }
  &-state ,
  &-zipcode {
    .modal-edit-address-input__label {
      width: auto;
      padding-right: 10px;
    }
  }
  &-footer {
    justify-content: flex-start;
    margin-top: 46px;
    .btn {
      height: 58px;
      @include font-size(16);
      @media #{$phone} {
        margin: 0 auto;
      }
    }
    .btn--danger {
      width: 155px;
    }
  }
  &-update {
    justify-content: flex-start;
    margin-top: 46px;
    margin-left: 16px;
    .btn {
      height: 58px;
      @include font-size(16);
    }
    .btn--danger {
      width: 155px;
    }
  }

}
.input-error {
  box-shadow: inset 0px 0px 0px 2px #EF0505;
  &:hover {
    box-shadow: inset 0px 0px 0px 2px #EF0505, 0px 14px 40px rgba(214, 214, 214, 0.4);
  }
}
.focused {
  box-shadow: 0px 14px 40px rgba(214, 214, 214, 0.4);
}

.upcoming {
  &-title {
    font-family: 'Graphik-Semibold';
    font-weight: 600;
    @include font-size(28);
    line-height: 1;
    letter-spacing: -0.466667px;
    color: #000000;
    margin-bottom: 28px;
  }
  &-plan {
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.2);
    box-shadow: 0px 12.5216px 10px rgba(0, 0, 0, 0.0118623), 0px 6.6501px 5px rgba(0, 0, 0, 0.00602135), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
    border-radius: 15px;
    padding: 20px;
    margin-bottom: 10px;
    max-width: 360px;
    box-sizing: border-box;
    &:last-of-type {
      margin-bottom: 0;
    }
    &-header {
      display: flex;
      gap: 6px;
      margin-bottom: 10px;
      align-items: center;
      &-icon {
        img {
          height: 21px;
          min-width: 21px;
        }
      }
      &-text {
        font-family: 'Graphik-Regular';
        font-weight: 400;
        @include font-size(16);
        line-height: 1.75;
        letter-spacing: -0.533333px;
        color: #000000;
      }
      &-date {
        font-family: 'Graphik-Medium';
        font-weight: 500;
      }
    }
    &-variant {
      display: flex;
      gap: 6px;
      padding: 16px 10px;
      border-bottom: 1px solid #EFEFEF;
      &:last-of-type {
        padding-bottom: 6px;
        border-bottom: none;
      }
      &-count {
        font-family: 'Graphik-Regular';
        font-weight: 400;
        @include font-size(18);
        line-height: 1.56;
        letter-spacing: -0.533333px;
        color: #000000;
      }
      &-name {
        font-family: 'Graphik-Medium';
        font-weight: 500;
        @include font-size(18);
        line-height: 1.56;
        letter-spacing: -0.533333px;
        color: #000000;
      }
    }
  }
}
.sidebar {
  overflow: hidden;
  display: block;
  width: 290px;
  position: fixed;
  height: 100%;
  left: 0;
  padding-left: 34px;
  padding-right: 34px;
  padding-top: 24px;
  padding-bottom: 24px;
  z-index: 1;
  @media #{$desktop} {
    display: none;
  }
  &-header {
    position: relative;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    &-logo {
      width: 45px;
      height: 42px;
      .disabled {
        pointer-events: none;
        cursor: default;
      }
    }
  }
  &-menu {
    display: block;
    margin-top: 45px;
  }
}
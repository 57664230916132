.customer-fallback {
  position: relative;
  overflow: hidden;
  background: #fff;
  width: 803px;
  padding: 60px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 20px;
  padding-bottom: 260px;
  background-size: auto auto;
  background-position: right bottom;
  background-repeat: no-repeat;
  box-shadow: 0px 12.5216px 12px rgba(0, 0, 0, 0.02), 0px 6.6501px 5px rgba(0, 0, 0, 0.01), 0px 2.76726px 2px rgba(0, 0, 0, 0.00203485);
  @media #{$ipad} {
    width: auto;
    padding: 20px;
    margin: 20px;
    background-size: 0px 0px;
  }
  @media #{$phone} {
    margin: 0px;
    margin-bottom: 20px;
  }
  &-title {
    margin-bottom: 40px;
    @media (min-width: $ipad-width) {
      font-size: 38px;
      line-height: 1.32;
      letter-spacing: -0.466667px;
    }
  }
  &-text {
    font-size: 18px;
    line-height: 120%;
    margin-bottom: 40px;
    @media #{$phone} {
      font-size: 14px;
    }
  }
  .btn {
    font-size: 16px;
    line-height: 1.13;
    text-align: center;
    letter-spacing: -0.285714px;
    @media #{$phone} {
      font-size: 14px;
    }
  }
}
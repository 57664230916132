button,
input[type="submit"],
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.btn,
input[type="submit"],
button[type="submit"] {
  background-color: $white;
  border: 1px solid $white;
  color:$black;
  display: inline-block;
  transition: .3s ease all;
  @include font-size(24);
  padding: 24px 34px;
  line-height: 1.2;
  text-decoration: none;
  font-family: $primary-family-medium;
  border-radius: 15px;
  max-width: 346px;
  text-align: left;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    border:1px solid $primary-color;
    background-color: $primary-color;
    color:$white;
    box-shadow: 0 14px 40px rgba($primary-color, 0.4);
    strong{
      color: $white;
    }
  }
}

.btn-cancel {
  display: flex;
  align-items: center;
  text-align: center;
  height: 58px;
  margin-left: 15px;

  .cancel-span-btn {
    font-weight: 400;
    font-size: 16px;
    line-height: 2.54;
    font-size: 16px;
    font-style: normal;
    line-height: 34px; /* 212.5% */
    text-decoration-line: underline;
    letter-spacing: -0.22px;
    color: #F42D53;
    cursor: pointer;
  }

  &.disabled {
    .cancel-span-btn {
      pointer-events: none;
      color: #E7E7E7;
    }
  }
}


.btn {
  &--fullwidth {
    width: 100%;
    max-width: 100%;
  }
  &--price{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    strong{
      color: $primary-color;
      font-family: $primary-family-semibold;
      @include font-size(20);
      margin-left:10px;
    }
  }
  &--primary,
  &--primary[type="submit"] {
    background-color: $primary-color;
    color:$white;
    text-align: center;
    border-radius: 40px;
    border:2px solid $primary-color;
    padding: 14px 20px;
    @include font-size(20);
    font-family: $primary-family-semibold;
    &:hover,
    &:active,
    &:focus {
      border:2px solid transparent;
      background-color: #1A8A81;
      box-shadow: 0 14px 40px rgba($primary-color, 0.2);
    }
    &:disabled {
      border:2px solid $light-gray;
      background-color: $light-gray;
      color:$white;
      box-shadow: none;
    }
  }
  &--secondary{
    text-align: center;
    background-color: $black;
    border:2px solid $black;
    @include font-size(20);
    color:$white;
    border-radius: 40px;
    padding: 14px 20px;
    font-family: $primary-family-semibold;
    &:hover,
    &:active,
    &:focus {
      border:2px solid $black;
      background-color: $white;
      color:$black;
      box-shadow: 0 14px 40px rgba($black, 0.2);
    }
  }
  &--secondary-white{
    text-align: center;
    background-color: $white;
    border:2px solid $white;
    box-shadow: 0px 6px 14px rgba(223, 223, 223, 0.5);
    border-radius: 28px;
    @include font-size(20);
    color:$black;
    padding: 14px 20px;
    font-family: $primary-family-semibold;
    &:hover,
    &:active,
    &:focus {
      border:2px solid $white;
      background-color: $black;
      color:$white;
      box-shadow: 0 14px 40px rgba($white, 0.2);
    }
    &:disabled {
      border:2px solid $light-gray;
      background-color: $light-gray;
      color:$black;
      box-shadow: none;
    }
  }
  &--danger{
    text-align: center;
    background-color: $red;
    border: 2px solid $red;
    box-shadow: 0px 6px 14px rgba(223, 223, 223, 0.5);
    border-radius: 28px;
    @include font-size(20);
    color:$white;
    padding: 14px 20px;
    font-family: $primary-family-semibold;
    &:hover,
    &:active,
    &:focus {
      border:2px solid $red;
      background-color: $white;
      color:$red;
      box-shadow: 0 14px 40px rgba($white, 0.2);
    }
    &:disabled {
      border:2px solid $light-gray;
      background-color: $light-gray;
      color:$black;
      box-shadow: none;
    }
  }
  &--danger-white {
    text-align: center;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 28px;
    @include font-size(20);
    color: $red;
    padding: 14px 20px;
    font-family: $primary-family-semibold;
    text-decoration: underline;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      border:2px solid $red;
      background-color: $white;
      color:$red;
      box-shadow: 0 14px 40px rgba($white, 0.2);
    }
    &:disabled {
      border:2px solid $light-gray;
      background-color: $light-gray;
      color:$black;
      box-shadow: none;
    }
  }
  &--wide {
    padding: 14px 10px;
  }
}

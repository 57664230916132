.checkbox {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &-hidden {
    height: 0;
    width: 0;
  }
}
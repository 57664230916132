.loader {
  display: flex;
  align-items: center;
  gap: 27px;
  &-bg {
    width: 100vw;
    height: 100vh;
    // background: rgba(16, 16, 16, 0.9);
    // background: rgba(166, 166, 166, 0.6);
    backdrop-filter: blur(5px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 14;
  }
  &-spinner {
    display: inline-block;
    position: relative;
    width: 51px;
    height: 51px;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 37px;
      height: 37px;
      margin: 7px;
      border: 7px solid #1CE4D5;
      border-radius: 50%;
      animation: loader-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #1CE4D5 #1CE4D5 #1CE4D5 rgba(225, 225, 225, 0.426);
    }
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
  &-text {
    font-family: 'Graphik-Medium';
    font-weight: 500;
    @include font-size(18);
    line-height: 1.56;
    letter-spacing: -0.5px;
    color: #000;
  }
}

@keyframes loader-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.welcome-block {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  &-left {
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 40px;
    padding-left: 120px;
    padding-right: 145px;
    width: 453px;
    min-width: 453px;
    box-sizing: unset;
    padding-bottom: 108px;
    @media #{$ipad} {
      padding-left: 0;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    @media #{$phone} {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 130px;
      margin-top: 0;
      width: 350px;
      min-width: unset;
    }
  }
  &-right {
    flex-grow: 1;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    @media #{$ipad} {
      display: none;
    }
    &-bg {
      position: absolute;
      width: calc(100% - 40px);
      height: 100%;
      background: linear-gradient(89.09deg, #EAEAEA 14.24%, #F5F5F5 124.39%);
      top: 0;
      right: 0;
    }
  }
  &__logo {
    position: absolute;
    cursor: pointer;
    top: 40px;
    left: 40px;
    @media #{$phone} {
      top: 30px;
      left: 30px;
      width: 28px;
      height: 26.96px;
    }
  }
  &__title {
    margin-bottom: 30px;
    max-width: 410px;
    @media #{$phone} {
      padding: 0;
      @include font-size(32);
      margin-bottom: 15px;
      max-width: 260px;
    }
    br{
      @media #{$phone} {
        display: none;
      }
    }
    img{
      max-width: 46px;
      display: inline-block;
      margin-top: -10px;
      @media #{$phone} {
        max-width: 28px;
      }
    }
  }
  &__content {
    margin-bottom: 37px;
    max-width: 400px;
    @media #{$phone} {
      max-width: 299px;
    }
    p {
      font-size: 20px;
      line-height: 1.5;
      @media #{$phone} {
        font-size: 18px;
        line-height: 1.44;
      }
    }
  }
  &__reset {
    &-link {
      text-decoration-line: underline;
      color: rgba(0, 0, 0, 0.8);
      font-size: 16px;
      cursor: pointer;
      @media #{$phone} {
        font-size: 14px;
        line-height: 1;
      }
    }
  }
  &__img {
    position: relative;
    max-width: unset;
    max-height: 100%;
    @media screen and (max-width: 1550px) {
      width: 115%;
    }
  }
  &__btn {
    @media #{$phone} {
      .btn {
        width: 100%;
      }
    }
  }
  &__form {
    .form-group {
      border: none;
      @media #{$phone} {
        width: 100%;
        border: 1px solid rgba($black, 0.1);
        padding: 18px 20px !important;
      }
      label{
        text-align: left;
        @media #{$phone} {
          display: none;
        }
      }
      .error{
        border: 1px solid red;
      }
    }
  }
  &-bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.modal-account-card-read {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border-radius: 15px;
  width: 100%;
  padding: 16px 24px;
  &__name {
    font-family: 'Graphik-Regular';
    font-weight: 400;
    font-size: 16px;
    line-height: 1.12;
    letter-spacing: -0.385882px;
    color: #404847;
  }
  &-card {
    display: flex;
    gap: 16px;
    margin-top: 6px;
    align-items: center;
    &__icon {
      img {
        max-width: 30px;
        max-height: 30px;
      }
    }
    &__number {
      font-family: 'Graphik-Semibold';
      font-weight: 600;
      font-size: 18px;
      line-height: 1;
      color: #ACACAC;
    }
  }
  &-right{
    &__button {
      cursor: pointer;
      img {
        fill: #ACACAC;
      }
    }
  }
}
.history-item-header-status {
  &-item {
    display: flex;
    gap: 6px;
    border-radius: 100px;
    padding: 6px;
    padding-right: 12px;
    align-items: center;
    &__text {
      font-size: 16px;
      line-height: 18px;
    }
  }
}
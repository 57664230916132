.referral {
  &-main {
    padding-right: 67.5px;
    padding-left: 67.5px;
    padding-top: 60px;
    padding-bottom: 53px;
    background-color: #fff;
    border-radius: 20px;
    min-height: 100%;
    max-width: 803px;
    width: 50%;
    @media screen and (max-width: 1400px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media #{$desktop} {
      margin-bottom: 38px;
    }
    @media #{$ipad} {
      margin-bottom: 0;
      width: 100%;
      max-width: 100%;
    }
    @media #{$phone} {
      padding: 20px;
      border-radius: 0;
      padding-top: 36px;
      padding-bottom: 24px;
      background-color: transparent;
      min-height: auto;
    }
    @media screen and (max-width: 600px) {
      padding-bottom: 24px;
    }
    &>h2 {
      margin-bottom: 40px;
    }
    .yotpo-widget-referral-widget .yotpo-background.yotpo-left-align-background {
      min-height: 700px;
    }
    .yotpo-share-buttons-container {
      margin-top: 30px !important;
    }
    .yotpo-tile-wrapper {
      @media #{$phone} {
        // width: 100%;
        min-height: auto !important;
        position: relative !important;
        border-radius: none !important;
        left: unset;
        transform: unset;
      }
    }
  }
  &-aside {
    @media #{$ipad} {
      width: 100%;
      max-width: 100%;
    }
  }
}
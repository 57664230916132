.auto-refill-plan-status-card {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 5px 8px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 71px;
  &__text {
    font-size: 16px;
    line-height: 1;
    color: #000000;
  }
}
.card-icon {
  max-width: 26.79px;
  max-height: 19.34px;
  min-width: 26.79px;
  min-height: 19.34px;
  img {
    object-fit: cover;
  }
}


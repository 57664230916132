.modal-sure {
  &__title {
    font-family: 'Graphik-Medium';
    font-weight: 500;
    @include font-size(24);
    line-height: 2.08;
    letter-spacing: -0.466667px;
    color: #000000;
    text-align: center;
    margin-bottom: 16px;
    margin-top: 48px;

    @media #{$phone} {
      font-family: 'Graphik-Semibold';
      font-weight: 600;
      font-size: 18px;
      line-height: 1.2;
      text-align: center;
      letter-spacing: -0.466667px;
      color: #000000;
      max-width: 251px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 23px;
    }

  }
  &-slider {
    margin-left: -16.5px;
    margin-right: -16.5px;
    @media screen and (max-width: 600px) {
      max-width: calc(100% - 86px);
      margin: 0 auto;
      .swiper-container {
        overflow: visible;
      }
    }
    @media screen and (max-width: 400px) {
      max-width: calc(100% - 50px);
    }
    &-item {
      padding: 24px 40px;
      background: #FFFFFF;
      border: 1px solid rgba(151, 151, 151, 0.2);
      border-radius: 12px;
      min-height: 301px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 600px) {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
      &__title {
        font-family: 'Graphik-Semibold';
        font-weight: 600;
        @include font-size(22);
        line-height: 1.27;
        letter-spacing: -0.5px;
        color: #000000;
        margin-bottom: 4px;
      }
      &__text {
        font-family: 'Graphik-Regular';
        font-weight: 400;
        @include font-size(16);
        line-height: 1.75;
        letter-spacing: -0.5px;
        color: #787878;
      }
      &-img {
        margin-bottom: 24px;
        height: 32px;
        width: auto;
        img {
          height: 100%;
        }
      }
      &-button {
        margin-top: auto;
        button {
          width: 100%;
          height: 44px;
          background: #FFFFFF;
          border: 1px solid #F7F7F7;
          box-shadow: 0px 3px 5px #D9D9D9;
          border-radius: 28px;

          font-family: 'Graphik-Semibold';
          font-weight: 600;
          @include font-size(16);
          line-height: 1.13;
          letter-spacing: -0.285714px;
          color: #000000;
        }
      }
    }
  }
  
}
.modal-cancel-sure {
  .modal__text {
    @media #{$phone} {
      text-align: center;
      max-width: 251px;
    }
  }
}
.modal-cancel-sure-second {
  .modal__title {
    // @media screen and (max-width: 600px) {
    //   text-align: center;
    //   font-size: 24px;
    //   line-height: 1.5;
    //   letter-spacing: -0.466667px;
    //   margin-bottom: 3px;
    // }
  }
  .modal__text {
    @media #{$phone} {
      text-align: center;
      max-width: 251px;
    }
  }
  .modal-sorry__text {
    font-size: 16px;
    line-height: 1.31;
    text-align: center;
    letter-spacing: -0.3px;
    color: #000000;
  }
}
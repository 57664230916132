.sidebar-logout-form {
  position: fixed;
  display: flex;
  align-items: center;
  width: 222px;
  padding: 23px 0;
  text-align: left;
  bottom: 34px;
  left: 34px;
  border-top: 2px solid #DCDCDC;
  &-right { 
    margin-left: 13px;
  } 
  &-name {
    font-family: 'Graphik-Medium';
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #000000;
  }
  &-btn {
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.22px;
    text-decoration-line: underline;
    color: #F42D53;
  }
}
html, body {
  height: 100%;
}

body,
button,
input,
select,
textarea {
  color: $black;
  font-family: $primary-family;
  @include font-size(18);
  line-height: $font__line-height-body;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: $primary-family-bold;
  margin: 0 0 20px 0;
  line-height: 1.1;
}

h1 {
  @include font-size(46);
  @media #{$phone} {
    @include font-size(32);
  }
}

h2 {
  @include font-size(40);
  @media #{$phone} {
    @include font-size(26);
  }
}

h3 {
  @include font-size(32);
  @media #{$phone} {
    @include font-size(24);
  }
}

h4 {
  @include font-size(26);
  @media #{$phone} {
    @include font-size(22);
  }
}

h5 {
  @include font-size(24);
  @media #{$phone} {
    @include font-size(22);
  }
}

h5 {
  @include font-size(22);
  @media #{$phone} {
    @include font-size(20);
  }
}

strong{
  font-weight: normal;
  font-family: $primary-family-bold;
}

p {
  margin: 0 0 20px;
  &:last-child{
    margin-bottom: 0;
  }
}

dfn, cite, em, i {
  font-style: italic;
}

blockquote {
  margin: 0;
}

address {
  margin: 0;
}

code, kbd, tt, var {
  font-family: $font__code;
  @include font-size(0.9375);
}

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark, ins {
  background: #fff9c0;
  text-decoration: none;
}

big {
  font-size: 125%;
}

:focus{
  outline: none !important;
}

.nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.bolder {
  font-weight: 600;
}

.bold {
  font-weight: 700;
}

.bg-none {
  background: none !important; 
}

.hidden {
  display: none;
}

.dashboard {
  display: flex;
  gap: 10px;
  border-radius: 20px;
  min-height: calc(100vh - 40px);
  box-sizing: border-box;
  width: 100%;
  background: #f8f8f8;
  @media #{$desktop} {
    width: 100%;
  }
  @media #{$ipad} {
    flex-direction: column;
  }
  @media #{$phone} {
    min-height: 100vh;
  }
  &-autorefill {
    // min-width: 803px;
    max-width: 803px;
    box-sizing: border-box;
    border-radius: 20px;
    flex-grow: 1;
    @media screen and (max-width: 1550px) {
      // min-width: auto;
      max-width: 803px;
      margin-bottom: 0;
    }
    @media #{$desktop} {
      background: none;
    }
    @media #{$phone} {
      padding: 20px;
      padding-top: 36px;
    }
  }
  &-aside {
    flex-grow: 1;
    padding: 63px 67px 45px 67px;
    background-color: #fff;
    border-radius: 20px;
    box-sizing: border-box;
    max-width: 543px;
    @media screen and (max-width: 1550px) {
      max-width: 803px;
    }
    @media screen and (max-width: 1400px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media #{$desktop} {
      // padding: 63px 67px 45px 67px;
    }
    @media #{$ipad} {
      margin-bottom: 38px;
    }
    @media #{$phone} {
      background: none;
      padding: 30px 20px;
    }
    @media screen and (max-width: 600px) {
      padding-top: 10px;
    }
  }
}
.nowrap {
  white-space: nowrap;
}

.random-icon {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  font-size: 23px;
  @media #{$phone} {
    font-size: 18px;
    width: 48px;
    height: 48px;
  }
  &-bg {
    &-BS {
      background: rgba($color: #FFC140, $alpha: .1);
    }
    &-CC {
      background: rgba($color: #F42D53, $alpha: .1);
    }
    &-BB {
      background: rgba($color: #3E4649, $alpha: .1);
    }
    &-RP {
      background: rgba($color: #8852F6, $alpha: .1);
    }
    &-HW {
      background: rgba($color: #F27E0F, $alpha: .1);
    }
    &-MB {
      background: rgba($color: #1CE4D5, $alpha: .1);
    }
    &-OR {
      background: rgba($color: #0A56EA, $alpha: .1);
    }
  }
}